import Authenticated from "./Layout/Authenticated";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import UdyamPage from "./screens/InProcess";
import AddUdyamPage from "./screens/AddUdyamPage";
import Dashboard from "./screens/Dashboard";
import UdyamInfo from "./screens/UdyamInfo";
import IndexPage from "./screens/IndexPage";
import IndexInfo from "./screens/IndexInfo";
import VerifyPage from "./screens/VerifyPage";
import Login from "./screens/LoginPage";
import AllUdyamPage from "./screens/AllUdyamPage";
import Users from "./screens/UserPage";
import EditProfile from "./screens/ProfileEdit";
import Test from "./screens/Test";
import ApprovedPage from "./screens/ApprovedPage";
import { Verify } from "./screens/Verify";

function App() {
  return (

      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/verify" element={<Verify />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/test" element={<Test />} />
          <Route exact path="/udyam" element={<UdyamPage />} />
          <Route exact path="/alludyam" element={<AllUdyamPage />} />
          <Route exact path="/approved" element={<IndexPage />} />
          <Route exact path="/addudyam" element={<AddUdyamPage />} />
          <Route exact path="/info/:id" element={<UdyamInfo />} />
          <Route exact path="/index/:id" element={<IndexInfo />} />
          <Route exact path="/verify/:id" element={<VerifyPage />} />
          <Route exact path="/users" element={<Users />} />
          <Route exact path="/edit" element={<EditProfile />} />
          <Route exact path="/approvedPage" element={<ApprovedPage />} />
        </Routes>
      </Router>

  );
}

export default App;
