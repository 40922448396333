import Authenticated from "../Layout/Authenticated";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Label,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { api, url as urlLink, axios } from "../axios";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Hourglass, InfinitySpin } from "react-loader-spinner";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { format } from "date-fns";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";

const people = [
  {
    year: "2022-23",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  // More people...
];

console.log(
  navigator.geolocation.getCurrentPosition((loc) => {
    console.log(loc);
  })
);

const rows = [
  {
    id: 1,
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const headCells = [
  {
    id: "udyam_id",
    numeric: false,
    disablePadding: false,
    label: "Udyam ID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "address",
    numeric: false,
    disablePadding: false,
    label: "Address",
    sortable: false,
  },
  {
    id: "contact",
    numeric: false,
    disablePadding: false,
    label: "Contact",
  },
  {
    id: "protein",
    numeric: true,
    sortable: false,
    disablePadding: false,
    label: "",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  return (
    <Toolbar
      sx={[
        {
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        },
        numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        },
      ]}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
export function Verify({ isOpen, isClose }) {
  const [show, setShow] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [query, setQuery] = useState("");
  const [selectedDistrict, setselectedDistrict] = useState(null);
  const [isBusy, setIsbusy] = useState(true);

  const [entities, setEntities] = useState({
    data: [],
  });
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [open, setOpen] = useState(false);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    console.log(orderBy, order, "i am sorting");
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    console.log(event, id);
    setOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    getData(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("i am changing rows per page");
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const district = [
    { id: 1, name: "Aizawl" },
    { id: 2, name: "Mamit" },
    { id: 3, name: "Lunglei" },
    { id: 4, name: "Champhai" },
    { id: 5, name: "Kolasib" },
    { id: 6, name: "Serchhip" },
    { id: 7, name: "Siaha" },
    { id: 8, name: "Lawngtlai" },
    // More users...
  ];

  const navigate = useNavigate();

  const filteredDistrict =
    query === ""
      ? district
      : district.filter((district) => {
          return district.name.toLowerCase().includes(query.toLowerCase());
        });

  const getData = async (page) => {
    setIsbusy(true);
    const url = new URL(`${urlLink}/api/unit`);
    page && url.searchParams.append("page", page);
    startDate && url.searchParams.append("startDate", startDate);
    selectedDistrict &&
      url.searchParams.append("district", selectedDistrict.toLowerCase());
    axios
      .get(url)
      .then((response) => {
        console.log(response);
        setEntities(response.data);
      })
      .finally(() => {
        setIsbusy(false);
      });

    return 0;
  };

  const checkUdyam = (udyam) => {
    console.log(udyam);
    navigate(`/verify/${udyam.id}`);
  };

  useEffect(() => {
    getData(1);
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8 p-3">
      <Dialog open={open} onClose={setOpen} className="relative z-30">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-8/12 sm:max-w-8/12 sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <div className=" sm:flex sm:flex-row items-center justify-center w-full">
                    <div className="text-lg font-semibold">
                      Udyam Registration Number :
                    </div>
                    <div className="text-lg font-light ml-3">
                      UDYAM-MZ-05-0002592
                    </div>
                  </div>

                  <div className="mt-10">
                    <div className="justify-center items-center">
                      <table className="min-w-full divide-y divide-gray-300">
                        <tbody className="divide-y divide-gray-200 bg-white">
                          <tr className="divide-x divide-gray-200">
                            <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
                              <div className="flex flex-row items-center justify-center">
                                <div className="font-bold">
                                  Type of Enterprise :{" "}
                                </div>
                                <div className="font-light ml-3">MICRO</div>
                              </div>
                            </td>
                            <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
                              <div className="flex flex-row items-center justify-center">
                                <div className="font-bold">
                                  Type of Organisation :{" "}
                                </div>
                                <div className="font-light ml-3">
                                  {" "}
                                  Proprietary
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr className="divide-x divide-gray-200">
                            <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
                              <div className="flex flex-row items-center justify-center">
                                <div className="font-bold">Owner Name : </div>
                                <div className="font-light ml-3">
                                  {" "}
                                  Manggali Roy
                                </div>
                              </div>
                            </td>
                            <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
                              <div className="flex flex-row items-center justify-center">
                                <div className="font-bold">
                                  Do you have GSTIN :{" "}
                                </div>
                                <div className="font-light ml-3">No</div>
                              </div>
                            </td>
                          </tr>
                          <tr className="divide-x divide-gray-200">
                            <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
                              <div className="flex flex-row items-center justify-center">
                                <div className="font-bold">Email Id : </div>
                                <div className="font-light ml-3">
                                  its.sotojit.the.gamer024@gmail.com
                                </div>
                              </div>
                            </td>
                            <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
                              <div className="flex flex-row items-center justify-center">
                                <div className="font-bold">Gender : </div>
                                <div className="font-light ml-3">Female</div>
                              </div>
                            </td>
                          </tr>
                          <tr className="divide-x divide-gray-200">
                            <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
                              <div className="flex flex-row items-center justify-center">
                                <div className="font-bold">
                                  Date of Incorporation :{" "}
                                </div>
                                <div className="font-light ml-3">
                                  {" "}
                                  24/02/2023
                                </div>
                              </div>
                            </td>
                            <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
                              <div className="flex flex-row items-center justify-center">
                                <div className="font-bold">
                                  Major Activity :
                                </div>
                                <div className="font-light ml-3">Services</div>
                              </div>
                            </td>
                          </tr>
                          <tr className="divide-x divide-gray-200">
                            <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
                              <div className="flex flex-row items-center justify-center">
                                <div className="font-bold">
                                  Name of Enterprise :
                                </div>
                                <div className="font-light ml-3">
                                  Digital Corner
                                </div>
                              </div>
                            </td>
                            <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
                              <div className="flex flex-row items-center justify-center">
                                <div className="font-bold">PAN :</div>
                                <div className="font-light ml-3">
                                  FZJPR6252N
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr className="divide-x divide-gray-200">
                            <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
                              <div className="flex flex-row items-center justify-center">
                                <div className="font-bold">Mobile Number :</div>
                                <div className="font-light ml-3">
                                  9366243994
                                </div>
                              </div>
                            </td>
                            <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
                              <div className="flex flex-row items-center justify-center">
                                <div className="font-bold">
                                  Social Category :
                                </div>
                                <div className="font-light ml-3">SC</div>
                              </div>
                            </td>
                          </tr>
                          <tr className="divide-x divide-gray-200">
                            <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
                              <div className="flex flex-row items-center justify-center">
                                <div className="font-bold">
                                  Specially Abled(DIVYANG) :
                                </div>
                                <div className="font-light ml-3">No</div>
                              </div>
                            </td>
                            <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
                              <div className="flex flex-row items-center justify-center">
                                <div className="font-bold">
                                  Date of Commencement of Production/Business :
                                </div>
                                <div className="font-light ml-3">
                                  24/02/2023
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="mt-2">
                    <div className="mx-20 my-10">
                      <div className="space-y-12">
                        <div className="border-b border-gray-900/10 pb-12"></div>
                        <div className="border-b border-gray-900/10 pb-12">
                          <h2 className="text-base font-semibold leading-7 text-gray-900">
                            Bank Details
                          </h2>
                          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className=" sm:col-span-2">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Bank Name
                              </label>
                              <div className="mt-2">
                                <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  Mizoram rural bank
                                </div>
                              </div>
                            </div>

                            <div className=" sm:col-span-2">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                IFS Code
                              </label>
                              <div className="mt-2">
                                <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  SBIN0RRMIGB
                                </div>
                              </div>
                            </div>

                            <div className="sm:col-span-2">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Bank Account Number
                              </label>
                              <div className="mt-2">
                                <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  97003540238
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="border-b border-gray-900/10 pb-12">
                          <h2 className="text-base font-semibold leading-7 text-gray-900">
                            Employment Details
                          </h2>
                          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4 place-items-center w-full">
                            <div className=" sm:col-span-1">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Male
                              </label>
                              <div className="mt-2">
                                <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  1
                                </div>
                              </div>
                            </div>

                            <div className=" sm:col-span-1">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Female
                              </label>
                              <div className="mt-2">
                                <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  1
                                </div>
                              </div>
                            </div>
                            <div className=" sm:col-span-1">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Other
                              </label>
                              <div className="mt-2">
                                <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  1
                                </div>
                              </div>
                            </div>
                            <div className=" sm:col-span-1">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Total
                              </label>
                              <div className="mt-2">
                                <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  3
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="border-b border-gray-900/10 pb-12">
                          <h2 className="text-base font-semibold leading-7 text-gray-900">
                            Investment in Plant and Machinery OR Equipment (in
                            Rs.)
                          </h2>
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                                >
                                  Financial Year
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Enterprise Type
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Written Down Value (WDV)
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Exclusion of cost of Pollution Control,
                                  Research & Development and Industrial Safety
                                  Devices
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Net Investment in Plant and Machinery OR
                                  Equipment[(A)-(B)]
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Total Turnover (A)
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Exclusion of cost of Pollution Control,
                                  Research & Development and Industrial Safety
                                  Devices
                                </th>
                                <th
                                  scope="col"
                                  className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                                >
                                  <span className="sr-only">Edit</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-white">
                              {people.map((person) => (
                                <tr
                                  key={person.email}
                                  className="even:bg-gray-50"
                                >
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                    {person.year}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {person.title}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {person.email}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {person.role}
                                  </td>
                                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                                    <a
                                      href="#"
                                      className="text-indigo-600 hover:text-indigo-900"
                                    >
                                      Edit
                                      <span className="sr-only">
                                        , {person.name}
                                      </span>
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className=" sm:col-span-2">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Financial Year
                              </label>
                              <div className="mt-2">
                                <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  2022-23
                                </div>
                              </div>
                            </div>

                            <div className=" sm:col-span-2">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Enterprise Type
                              </label>
                              <div className="mt-2">
                                <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  Micro
                                </div>
                              </div>
                            </div>

                            <div className=" sm:col-span-2">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Written Down Value (WDV)
                              </label>
                              <div className="mt-2">
                                <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  0.00
                                </div>
                              </div>
                            </div>

                            <div className=" sm:col-span-2">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Exclusion of cost of Pollution Control, Research
                                & Development and Industrial Safety Devices
                              </label>
                              <div className="mt-2">
                                <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  0.00
                                </div>
                              </div>
                            </div>

                            <div className=" sm:col-span-2">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Net Investment in Plant and Machinery OR
                                Equipment[(A)-(B)]
                              </label>
                              <div className="mt-2">
                                <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  0.00
                                </div>
                              </div>
                            </div>

                            <div className=" sm:col-span-2">
                              <label
                                htmlFor="country"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Total Turnover (A)
                              </label>
                              <div className="mt-2">
                                <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  5000.00
                                </div>
                              </div>
                            </div>

                            <div className=" sm:col-span-2">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Export Turnover (B)
                              </label>
                              <div className="mt-2">
                                <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  0.00
                                </div>
                              </div>
                            </div>

                            <div className=" sm:col-span-2">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Net Turnover [(A)-(B)]
                              </label>
                              <div className="mt-2">
                                <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  5000.00
                                </div>
                              </div>
                            </div>

                            <div className=" sm:col-span-2">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Is ITR Filled?
                              </label>
                              <div className="mt-2">
                                <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  No
                                </div>
                              </div>
                            </div>

                            <div className=" sm:col-span-2">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                ITR Type
                              </label>
                              <div className="mt-2">
                                <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  NA
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="border-b border-gray-900/10 pb-12">
                          <h2 className="text-base font-semibold leading-7 text-gray-900">
                            Unit(s) Details
                          </h2>
                          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className=" sm:col-span-2">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Unit Name
                              </label>
                              <div className="mt-2">
                                <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  Digital Corner
                                </div>
                              </div>
                            </div>

                            <div className=" sm:col-span-2">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Flat
                              </label>
                              <div className="mt-2">
                                <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  43
                                </div>
                              </div>
                            </div>

                            <div className=" sm:col-span-2">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Building
                              </label>
                              <div className="mt-2">
                                <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  Near Mrb Serkawn
                                </div>
                              </div>
                            </div>

                            <div className=" sm:col-span-2">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Village/Town
                              </label>
                              <div className="mt-2">
                                <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  Serkawn
                                </div>
                              </div>
                            </div>
                            <div className=" sm:col-span-2">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Block
                              </label>
                              <div className="mt-2">
                                <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  Serkawn, lunglei
                                </div>
                              </div>
                            </div>

                            <div className=" sm:col-span-2">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Road
                              </label>
                              <div className="mt-2">
                                <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  Near Mrb Serkawn
                                </div>
                              </div>
                            </div>

                            <div className=" sm:col-span-2">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                City
                              </label>
                              <div className="mt-2">
                                <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  Lunglei
                                </div>
                              </div>
                            </div>

                            <div className=" sm:col-span-2">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Pin
                              </label>
                              <div className="mt-2">
                                <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  796691
                                </div>
                              </div>
                            </div>
                            <div className=" sm:col-span-2">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                State
                              </label>
                              <div className="mt-2">
                                <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  MIZORAM
                                </div>
                              </div>
                            </div>
                            <div className=" sm:col-span-2">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                District
                              </label>
                              <div className="mt-2">
                                <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  LUNGLEI
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="border-b border-gray-900/10 pb-12">
                        <h2 className="text-base font-semibold leading-7 mt-5 text-gray-900">
                          Official address of Enterprise
                        </h2>
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                          <div className=" sm:col-span-2">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              Flat/Door/Block No.
                            </label>
                            <div className="mt-2">
                              <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                43
                              </div>
                            </div>
                          </div>

                          <div className=" sm:col-span-2">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              Village/Town
                            </label>
                            <div className="mt-2">
                              <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                Serkawn
                              </div>
                            </div>
                          </div>

                          <div className=" sm:col-span-2">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              Road/Street/Lane
                            </label>
                            <div className="mt-2">
                              <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                Near Mrb Serkawn
                              </div>
                            </div>
                          </div>

                          <div className=" sm:col-span-2">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              State
                            </label>
                            <div className="mt-2">
                              <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                MIZORAM
                              </div>
                            </div>
                          </div>
                          <div className=" sm:col-span-2">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              Mobile
                            </label>
                            <div className="mt-2">
                              <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                9366243994
                              </div>
                            </div>
                          </div>

                          <div className=" sm:col-span-2">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              Name of Premises/ Building
                            </label>
                            <div className="mt-2">
                              <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                Near Mrb Serkawn
                              </div>
                            </div>
                          </div>

                          <div className=" sm:col-span-2">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              Block
                            </label>
                            <div className="mt-2">
                              <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                Serkawn, lunglei
                              </div>
                            </div>
                          </div>

                          <div className=" sm:col-span-2">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              City
                            </label>
                            <div className="mt-2">
                              <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                Lunglei
                              </div>
                            </div>
                          </div>
                          <div className=" sm:col-span-2">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              District
                            </label>
                            <div className="mt-2">
                              <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                LUNGLEI , Pin : 796691
                              </div>
                            </div>
                          </div>
                          <div className=" sm:col-span-2">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              Email
                            </label>
                            <div className="mt-2">
                              <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                its.sotojit.the.gamer024@gmail.com
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="border-b border-gray-900/10 pb-12">
                        <h2 className="text-base font-semibold leading-7 mt-5 text-gray-900">
                          National Industry Classification Code(S)
                        </h2>
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                          <div className=" sm:col-span-2">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              Nic 2 Digit
                            </label>
                            <div className="mt-2">
                              <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                63 - Information service activities
                              </div>
                            </div>
                          </div>

                          <div className=" sm:col-span-2">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              Nic 4 Digit
                            </label>
                            <div className="mt-2">
                              <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                6399 - Other information service activities
                                n.e.c.
                              </div>
                            </div>
                          </div>

                          <div className=" sm:col-span-2">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              Nic 5 Digit
                            </label>
                            <div className="mt-2">
                              <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                63992 - Activities of cyber café
                              </div>
                            </div>
                          </div>

                          <div className=" sm:col-span-2">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              Activity
                            </label>
                            <div className="mt-2">
                              <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                Services
                              </div>
                            </div>
                          </div>

                          <div className=" sm:col-span-2">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              Are you interested to get registered on Government
                              e-Market (GeM) Portal
                            </label>
                            <div className="mt-2">
                              <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                No
                              </div>
                            </div>
                          </div>

                          <div className=" sm:col-span-2">
                            <label
                              htmlFor="country"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Are you interested to get registered on TReDS
                              Portals(one or more)
                            </label>
                            <div className="mt-2">
                              <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                No
                              </div>
                            </div>
                          </div>

                          <div className=" sm:col-span-2">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              Are you interested to get registered on National
                              Career Service(NCS) Portal
                            </label>
                            <div className="mt-2">
                              <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                No
                              </div>
                            </div>
                          </div>

                          <div className=" sm:col-span-2">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              DIC
                            </label>
                            <div className="mt-2">
                              <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                LUNGLEI
                              </div>
                            </div>
                          </div>

                          <div className=" sm:col-span-2">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              MSME-DI
                            </label>
                            <div className="mt-2">
                              <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                AGARTALA
                              </div>
                            </div>
                          </div>

                          <div className=" sm:col-span-2">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              Date of Udyam Registration
                            </label>
                            <div className="mt-2">
                              <div className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                22/09/2024
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  onClick={() => setOpen(false)}
                  className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Go back to dashboard
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
      <div className="">
        <div className="grid grid-cols-4 gap-x-4">
          <TextField
            label="Owner Name"
            className="w-full"
            size="small"
            variant="outlined"
          />
          <TextField
            label="Email"
            className="w-full"
            size="small"
            variant="outlined"
          />
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex flex-row place-items-center">
          {/* <Link to="/addudyam">
              <button
                type="button"
                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Add Udyam
              </button>
            </Link> */}
        </div>
      </div>
      <div className=" mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="">
          <TextField
            className="w-full"
            select
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedDistrict}
            label="District"
            onChange={(e) => setselectedDistrict(e.target.value)}
          >
            {district.map((dist) => (
              <MenuItem key={dist.id} value={dist.name}>
                {dist.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              slotProps={{ textField: { size: "small" } }}
              label="From Date"
              onChange={(newValue) =>
                setStartDate(format(new Date(newValue.$d), "yyyy-MM-dd"))
              }
              className=""
            />
          </LocalizationProvider>
        </div>

        <div className="">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              slotProps={{ textField: { size: "small" } }}
              label="From Date"
              onChange={(newValue) =>
                setStartDate(format(new Date(newValue.$d), "yyyy-MM-dd"))
              }
              className=""
            />
          </LocalizationProvider>
        </div>
        <div className=" grid grid-cols-2  gap-x-3 mx-3">
          <Button variant="contained" color="error" size="large">
            Cancel
          </Button>
          <Button variant="contained" size="large">
            Submit
          </Button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          {isBusy ? (
            <div className="flex justify-center">
              <InfinitySpin
                visible={true}
                width="200"
                color="#4fa94d"
                ariaLabel="infinity-spin-loading"
              />
            </div>
          ) : (
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              {/* <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                      >
                        Udyam
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Address
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Contact
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {entities?.data.map((entity, index) => (
                      <tr key={entity.id}>
                        <td className="whitespace-nowrap py-10 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                          {entity.udyam_id}/{entity?.id}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {entity.owner_name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {entity.official_address?.district}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {entity.mobile}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                          {entity.checks?.length ? (
                            <div className="">
                              {entity.checks.map((check) => {
                                return (
                                  <div
                                    className="flex justify-right w-full"
                                    key={check.id}
                                  >
                                    {
                                      check?.user?.name
                                    }
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            <button
                              className="text-white bg-blue-700 hover:bg-blue-500 w-28 h-10 rounded-md"
                              onClick={() => checkUdyam(entity)}
                            >
                              Check
                              <span className="sr-only"> {entity.name}</span>
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>


                <div className="flex justify-center">
                  <Pagination
                    count={Math.ceil(entities.total / entities.per_page)}
                    page={entities.current_page}
                    onChange={(e, number) => getData(number)}
                  />
                </div> */}
              <Box sx={{ width: "100%" }}>
                <Paper sx={{ width: "100%", mb: 2 }}>
                  {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 750 }}
                      aria-labelledby="tableTitle"
                      size={dense ? "small" : "medium"}
                    >
                      <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                      />
                      <TableBody>
                        {entities?.data.map((row, index) => {
                          const isItemSelected = selected.includes(row.id);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              onClick={(event) => handleClick(event, row.id)}
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                              sx={{ cursor: "pointer" }}
                            >
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="left"
                              >
                                {row.entity.udyam_id}
                              </TableCell>
                              <TableCell align="left">{row.name}</TableCell>
                              <TableCell align="left">
                                {row?.district}
                              </TableCell>
                              <TableCell align="left">
                                {row.entity.mobile}
                              </TableCell>
                              <TableCell align="left">
                                {row.checks?.length ? (
                                  <div className="">
                                    {row.checks.map((check) => {
                                      return (
                                        <div
                                          className="flex justify-right w-full"
                                          key={check.id}
                                        >
                                          {check?.user?.name}
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  <button
                                    className="text-white bg-blue-700 hover:bg-blue-500 w-28 h-10 rounded-md"
                                    onClick={() => {
                                      navigate(`/verify/${row.id}`);
                                    }}
                                  >
                                    Verify Now
                                    <span className="sr-only"> {row.name}</span>
                                  </button>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        {entities?.data.length - entities?.per_page > 0 && (
                          <TableRow
                            style={{
                              height:
                                (dense ? 33 : 53) *
                                (entities?.data.length - entities?.per_page),
                            }}
                          >
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <div className="relative py-3">
                    <div className="mt-2 absolute left-1/2 -translate-x-1/2 flex justify-center z-30">
                      <Pagination
                        count={Math.ceil(entities.total / entities.per_page)}
                        page={entities.current_page}
                        onChange={(e, number) => getData(number)}
                      />
                    </div>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 20]}
                      component="div"
                      count={entities?.total}
                      rowsPerPage={entities?.per_page}
                      page={entities?.current_page - 1}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                </Paper>
              </Box>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
