import React, { useEffect, useState } from "react";
import Authenticated from "../Layout/Authenticated";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { api } from "../axios";
import { Pagination } from "@mui/material";
import { InfinitySpin } from "react-loader-spinner";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Users() {
  // variable
  const [open, setOpen] = useState(false);
  const [isBusy, setIsBusy] = useState(true);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, set_password_confirmation] = useState("");
  const [error, setError] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
  });
  const [users, setUsers] = useState({
    data: [],
  });
  const [role, setRole] = React.useState("");

  // functions
  const handleChange = (event) => {
    setRole(event.target.value);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const editUser = (user) => {
    setOpen(true);
    setTimeout(() => {
      setId(user?.id);
      setName(user?.name);
      setPhone(user?.phone);
      setEmail(user?.email);
      set_password_confirmation("");
      setPassword("");
      setRole(user?.roles[0]?.name);
    });
  };
  const getData = async (page) => {
    setIsBusy(true);
    const url = `api/user?page=${page}`;
    api
      .get(url)
      .then((response) => {
        console.log(response);
        setUsers(response.data);
      })
      .finally(() => {
        setIsBusy(false);
      });
  };

  const submit = (e) => {
    e.preventDefault();
    console.log({
      id,
      name,
      email,
      phone,
      role,
      password,
      password_confirmation,
    });
  };

  // useEffect

  useEffect(() => {
    getData(1);
  }, []);

  useEffect(() => {
    setId("");
    setEmail("");
    setPassword("");
    set_password_confirmation("");
    setPhone("");
    setRole("");
  }, [open]);

  return (
    <Authenticated>
      <div className="px-4 sm:px-6 lg:px-8">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className=" justify-center items-center w-full font-semibold text-xl text-center">
              {id ? "Edit user" : "Add User"}
            </div>
            <form onSubmit={submit}>
              <div className=" justify-center place-items-center w-full">
                <div className="mt-2">
                  <TextField
                    autoComplete="off"
                    label="Name"
                    error={Boolean(error?.name)}
                    helperText={error?.name || " "}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    variant="outlined"
                    className=" w-full"
                  />
                </div>
                <div className="mt-2">
                  <TextField
                    autoComplete="off"
                    label="Phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    variant="outlined"
                    error={Boolean(error?.phone)}
                    helperText={error?.phone || " "}
                    type="number"
                    className=" w-full"
                  />
                </div>
                <div className="mt-2">
                  <TextField
                    autoComplete="false"
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={Boolean(error?.email)}
                    helperText={error?.email || " "}
                    variant="outlined"
                    className=" w-full"
                  />
                </div>
                <div className="mt-2">
                  <TextField
                    label="Password"
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={Boolean(error?.password)}
                    helperText={error?.password || " "}
                    type="password"
                    className=" w-full"
                  />
                </div>
                <div className="mt-2">
                  <TextField
                    label="Re-enter Password"
                    value={password_confirmation}
                    onChange={(e) => set_password_confirmation(e.target.value)}
                    error={Boolean(error?.password_confirmation)}
                    helperText={error?.password_confirmation || " "}
                    variant="outlined"
                    type="password"
                    className=" w-full"
                  />
                </div>
                <div className="mt-2">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Role</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={role}
                      label="Role"
                      onChange={handleChange}
                    >
                      <MenuItem value={"admin"}>Admin</MenuItem>
                      <MenuItem value={"superadmin"}>SuperAdmin</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="mt-10 flex flex-row justify-between">
                <button
                  className="h-10 bg-blue-500 text-white hover:bg-blue-400 w-20 rounded-md"
                  type="submit"
                >
                  Save
                </button>
                <button
                  onClick={handleClose}
                  className="h-10 bg-red-500 text-white hover:bg-red-400 w-20 rounded-md"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Box>
        </Modal>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Users
            </h1>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => editUser()}
            >
              Add user
            </button>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="min-h-full">
                {isBusy ? (
                  <div className="flex justify-center">
                    <InfinitySpin
                      visible={true}
                      width="200"
                      color="#4fa94d"
                      ariaLabel="infinity-spin-loading"
                    />
                  </div>
                ) : (
                  <div className="min-h-72">
                    <table className="min-w-full  divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                          >
                            ID
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Phone
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Email
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Role
                          </th>
                          <th
                            scope="col"
                            className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                          >
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {users?.data.map((person, index) => (
                          <tr key={person.email}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                              {person.name}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {person.title}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {person.email}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {person.email}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {person.role}
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                              <button
                                onClick={() => editUser(person)}
                                className="text-indigo-600 hover:text-indigo-900"
                              >
                                Edit
                                <span className="sr-only">, {person.name}</span>
                              </button>
                              <a
                                href="#"
                                className="text-red-600 hover:text-red-500 ml-5"
                              >
                                Delete
                                <span className="sr-only">, {person.name}</span>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="flex justify-center">
                      <Pagination
                        count={Math.ceil(users.total / users.per_page)}
                        page={users.current_page}
                        onChange={(e, number) => getData(number)}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Authenticated>
  );
}
