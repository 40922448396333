import * as React from "react";
import Authenticated from "../Layout/Authenticated";
import { PieChart } from "@mui/x-charts/PieChart";
import { LineChart } from "@mui/x-charts/LineChart";
import { BarChart } from "@mui/x-charts/BarChart";

export default function Dashboard() {
  return (
    <Authenticated>
      <div className="">
        <div className=""></div>
        <div className=" flex flex-row justify-between place-items-center">
          <div className="">
            <PieChart
              series={[
                {
                  data: [
                    { id: 0, value: 400, label: "Aizawl" },
                    { id: 1, value: 667, label: "Lunglei" },
                    { id: 2, value: 1070, label: "Champhai" },
                    { id: 5, value: 920, label: "Serchhip" },
                    { id: 6, value: 1297, label: "Kolasib" },
                    { id: 7, value: 436, label: "Mamit" },
                    { id: 9, value: 188, label: "Siaha" },
                    { id: 210, value: 365, label: "Lawngtlai" },
                  ],
                },
              ]}
              width={700}
              height={300}
            />
          </div>
          <div className="">
            <BarChart
              xAxis={[
                {
                  scaleType: "band",
                  data: ["Service", "Manufacturing", "Others"],
                },
              ]}
              series={[
                { data: [10000, 12000, 500], label: "No. of Registered Udyam" },
                { data: [5000, 8000, 200], label: "Verified" },
              ]}
              width={700}
              height={300}
            />
          </div>
          {/* <div className="place-items-center justify-center">
            <div className="">Growth Rate</div>
            <LineChart
              xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
              series={[
                {
                  data: [2, 5.5, 2, 8.5, 1.5, 5],
                },
              ]}
              width={500}
              height={300}
            />
          </div> */}
        </div>
      </div>
    </Authenticated>
  );
}
