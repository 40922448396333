import Authenticated from "../Layout/Authenticated";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Label,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { api } from "../axios";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Hourglass } from "react-loader-spinner";

export default function AllUdyamPage({ isOpen, isClose }) {
  const [show, setShow] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [query, setQuery] = useState("");
  const [selectedDistrict, setselectedDistrict] = useState(null);
  const [isBusy, setIsbusy] = useState(true);

  const [entities, setEntities] = useState({
    data: [],
  });
  const district = [
    { id: 1, name: "Aizawl" },
    { id: 2, name: "Mamit" },
    { id: 3, name: "Lunglei" },
    { id: 4, name: "Champhai" },
    { id: 5, name: "Kolasib" },
    { id: 6, name: "Serchhip" },
    { id: 7, name: "Siaha" },
    { id: 8, name: "Lawngtlai" },
    // More users...
  ];

  const navigate = useNavigate();

  const filteredDistrict =
    query === ""
      ? district
      : district.filter((district) => {
          return district.name.toLowerCase().includes(query.toLowerCase());
        });

  const getData = async (page) => {
    // const controller = new AbortController()
    const url = `api/entity?page=${page}`;
    api
      .get(url, {
        // signal:controller.signal
      })
      .then((response) => {
        console.log(response);
        setEntities(response.data);
      })
      .finally(() => {
        setIsbusy(false);
      });
    // return () => {
    //   controller.abort()
    // }
  };
  const clickinfo = (entity) => {
    navigate("/info/" + entity.id);
  };
  const checkUdyam = (udyam) => {
    api.post("api/check/store", {
      entity_id: udyam?.id,
      status: "completed",
    });
  };

  useEffect(() => {
    getData(1);
  }, []);

  return (
    <Authenticated>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center place-items-center justify-center">
          <div className="sm:flex-auto">
            <form
              action="#"
              method="GET"
              className="relative flex w-2/2 h-14 rounded-md border-2"
            >
              <label htmlFor="search-field" className="sr-only">
                Search
              </label>
              <MagnifyingGlassIcon
                aria-hidden="true"
                className="pointer-events-none absolute inset-y-0 left-5 h-full w-5 text-gray-400"
              />
              <input
                id="search-field"
                name="search"
                type="search"
                placeholder="Search..."
                className="block h-full w-full border-0 py-0 pl-12 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
              />
            </form>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex flex-row place-items-center">
            {/* <Link to="/addudyam">
              <button
                type="button"
                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Add Udyam
              </button>
            </Link> */}
            <button
              type="file"
              className="block ml-5 h-14 w-40 rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Upload
            </button>
          </div>
        </div>
        <div className=" mt-5 flex flex-row  place-items-center w-full">
          <div className="">
            <Combobox
              as="div"
              value={selectedDistrict}
              onChange={(district) => {
                setQuery("");
                setselectedDistrict(district);
              }}
              className=" z-50"
            >
              <Label className="block text-sm font-medium leading-6 text-gray-900">
                Select District
              </Label>
              <div className="relative">
                <ComboboxInput
                  className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(event) => setQuery(event.target.value)}
                  onBlur={() => setQuery("")}
                  displayValue={(district) => district?.name}
                />
                <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </ComboboxButton>

                {filteredDistrict.length > 0 && (
                  <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {filteredDistrict.map((district) => (
                      <ComboboxOption
                        key={district.id}
                        value={district}
                        className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                      >
                        <span className="block truncate group-data-[selected]:font-semibold">
                          {district.name}
                        </span>

                        <span className="absolute inset-y-0 right-0 hidden items-center pr-4 text-indigo-600 group-data-[selected]:flex group-data-[focus]:text-white">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      </ComboboxOption>
                    ))}
                  </ComboboxOptions>
                )}
              </div>
            </Combobox>
          </div>
          <div className=" ml-5">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              From
            </label>
            <div className="">
              <input
                type="date"
                className="block w-60 pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="ml-5">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              To
            </label>
            <div className="">
              <input
                type="date"
                className="block w-60 pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="flex flex-row place-items-center mt-5">
            <div>
              <button
                type="button"
                className="block ml-5  w-40 rounded-md bg-red-800 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Clear
              </button>
            </div>
            <div>
              <button
                type="button"
                className="block ml-5 w-40 rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            {isBusy ? (
              <Hourglass
                visible={true}
                height="80"
                width="80"
                ariaLabel="hourglass-loading"
                wrapperStyle={{}}
                wrapperClass=""
                colors={["#306cce", "#72a1ed"]}
              />
            ) : (
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                      >
                        Udyam
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Address
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Contact
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {entities?.data.map((entity, index) => (
                      <tr
                        onClick={() => clickinfo(entity)}
                        className=" cursor-pointer hover:bg-gray-50"
                        key={entity.id}
                      >
                        <td className="whitespace-nowrap py-10 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                          {entity.udyam_id}/{entity?.id}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {entity.owner_name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {entity.official_address?.district}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {entity.mobile}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                          {entity.checks?.length ? (
                            <div className="">
                              {entity.checks.map((check) => {
                                return (
                                  <div
                                    className="flex justify-right w-full"
                                    key={check.id}
                                  >
                                    {check?.user?.name}
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            <button
                              className="text-white bg-blue-700 hover:bg-blue-500 w-28 h-10 rounded-md"
                              onClick={() => checkUdyam(entity)}
                            >
                              Check
                              <span className="sr-only"> {entity.name}</span>
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className=""></div>

                <div className="flex justify-center">
                  <Pagination
                    count={Math.ceil(entities.total / entities.per_page)}
                    page={entities.current_page}
                    onChange={(e, number) => getData(number)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Authenticated>
  );
}
