/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import {
  APIProvider,
  Map,
  Pin,
  AdvancedMarker,
  ControlPosition,
  MapControl,
} from "@vis.gl/react-google-maps";

const App = () => {
  const [location, setLocation] = useState({
    lat: 0,
    lng: 0,
  });

  const getLocation = async () => {
    await navigator.geolocation.getCurrentPosition(
      async (loc) => {
        console.log(loc);
        setLocation({ lat: loc.coords.latitude, lng: loc.coords.longitude });
      },
      (err) => alert(JSON.stringify(err))
    );
  };

  useEffect(() => {
    getLocation();
  }, []);

  return location.lat && location.lng ? (
    <APIProvider apiKey={"AIzaSyAey-iLRqhbDTU4h6HbWF4fCTfPu5Tu0J4"}>
      <Map
        onClick={(e) =>
          setLocation({ lng: e.detail.latLng.lng, lat: e.detail.latLng.lat })
        }
        mapId={"40f13a2318c04c92"}
        defaultZoom={20}
        gestureHandling={"greedy"}
        style={{ height: "100vh", width: "100vh" }}
        defaultCenter={{ lat: location.lat, lng: location.lng }} // New York City coordinates
      >
        <MapControl position={ControlPosition.TOP_LEFT}>
          .. any component here will be added to the control-containers of the
          google map instance ..
        </MapControl>
        <AdvancedMarker
          draggable={true}
          position={{ lat: location.lat, lng: location.lng }}
        >
          <Pin
            background={"#FBBC04"}
            glyphColor={"#000"}
            borderColor={"#000"}
          />
        </AdvancedMarker>
      </Map>
    </APIProvider>
  ) : (
    <h1>Should be busy</h1>
  );
};

export default App;
