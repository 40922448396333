import { createSlice } from "@reduxjs/toolkit";

const initialState = {

    name: "",

    email: "",
    phone: "",
    roles: [],

};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.phone = action.payload.phone;
      state.roles = action.payload.roles;
    },
  },
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer;
