import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import {
  APIProvider,
  Map,
  Pin,
  AdvancedMarker,
  ControlPosition,
  MapControl,
} from "@vis.gl/react-google-maps";
import { api, axios, url } from "../axios";
import { Button, Checkbox } from "@mui/material";
import { useParams } from "react-router-dom";

export default function VerifyPage() {
  const [is_active, set_is_active] = useState(true);
  const [location, setLocation] = useState({
    lat: 0,
    lng: 0,
  });
  const [apiKey, setApiKey] = useState("");
  const [image, setImage] = useState();
  const [imageUrl, setImageUrl] = useState();
  const param = useParams();
  const getLocation = async () => {
    await api.get("api/google_api_key").then((res) => setApiKey(res.data));
    await navigator.geolocation.getCurrentPosition(
      async (loc) => {
        setLocation({ lat: loc.coords.latitude, lng: loc.coords.longitude });
      },
      (err) => alert(JSON.stringify(err))
    );
  };

  const submit = (e) => {
    e.preventDefault();
    const form = {
      unit_id: param.id,
      latitude: location.lat,
      longitude: location.lng,
      image: image,
      is_active: is_active,
      status: "completed",
    };

    console.log(form);

    axios
      .post(url + "/api/verify", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => console.log(res));
  };

  const handleInputChange = (event) => {
    const size = event.target.files[0].size / 10000000;
    if (size > 8) {
      alert("File size should be less than 8 MB");
      return 0;
    }
    setImage(event.target.files[0]);
    setImageUrl(URL.createObjectURL(event.target.files[0]));
  };
  useEffect(() => {
    getLocation();
  }, []);

  return (
    <div>
      <form onSubmit={submit}>
        <div className="my-10 mx-10">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Verify Udyam
            </h2>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              {location.lat && location.lng ? (
                <APIProvider apiKey={apiKey}>
                  <Map
                    onClick={(e) =>
                      setLocation({
                        lng: e.detail.latLng.lng,
                        lat: e.detail.latLng.lat,
                      })
                    }
                    mapId={"40f13a2318c04c92"}
                    defaultZoom={20}
                    gestureHandling={"greedy"}
                    style={{ height: "50vh", width: "100vw" }}
                    defaultCenter={{ lat: location.lat, lng: location.lng }}
                  >
                    <MapControl position={ControlPosition.TOP_LEFT}>
                      .. any component here will be added to the
                      control-containers of the google map instance ..
                    </MapControl>
                    <AdvancedMarker
                      draggable={true}
                      position={{ lat: location.lat, lng: location.lng }}
                    >
                      <Pin
                        background={"#FBBC04"}
                        glyphColor={"#000"}
                        borderColor={"#000"}
                      />
                    </AdvancedMarker>
                  </Map>
                </APIProvider>
              ) : (
                <h1>Should be busy</h1>
              )}
              <div className="sm:col-span-3"></div>

              <div className="sm:col-span-3">
                <TextField
                  required
                  onChange={(e) =>
                    setLocation({ lng: location.lng, lat: e.target.value })
                  }
                  value={location.lat}
                  id="outlined-basic"
                  label="GPS latitude"
                  className="w-full"
                  variant="outlined"
                />
              </div>
              <div className="sm:col-span-3">
                <TextField
                  onChange={(e) =>
                    setLocation({
                      lat: location.lat,
                      lng: parseFloat(e.target.value),
                    })
                  }
                  value={location.lng}
                  id="outlined-basic"
                  label="GPS longitude"
                  className="w-full"
                  variant="outlined"
                />
              </div>
              <div className="sm:col-span-3">
                <div className="mt-2">
                  <span className="">Active for 3 Years</span>

                  <Checkbox
                    label="Active"
                    checked={is_active}
                    onChange={(e) => set_is_active(e.target.checked)}
                  />
                </div>
              </div>
              <div className="sm:col-span-3 relative">
                {!imageUrl ? (
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={() => {
                      document.getElementById("file").click();
                    }}
                    className="z-30"
                  >
                    Upload Image
                  </Button>
                ) : (
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={() => {
                      setImage(null);
                      setImageUrl(null);
                    }}
                    className="z-30"
                  >
                    Remove Image
                  </Button>
                )}
                <input
                  onChange={handleInputChange}
                  accept="image/*"
                  label="Upload File"
                  id="file"
                  type="file"
                  required
                  className="  absolute left-0 w-1 h-1"
                  variant="outlined"
                />
                {imageUrl && (
                  <div className="mt-10">
                    <img src={imageUrl} />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="submit"
              className="rounded-md w-52 bg-[#710202] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#950202] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="rounded-md w-52 bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Verify
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
